import type { ParentComponent } from "solid-js";
import { createContext, useContext, DEV } from "solid-js";
import { createStore } from "solid-js/store";
import type { Landing, Lot, ParagraphForm } from "~/types/drupal_jsonapi";
import type { FormSystemName } from "~/types/form_system_name";
import type { ProgramWrapper } from "~/utils/program_wrapper";

export type OpenModalFormOptions = {
  wrapper?: ProgramWrapper;
  lot?: Lot;
  landing?: Landing;
  formConfig?: ParagraphForm;
  previousFormSystemName?: FormSystemName;
  modalHeadline?: string;
  modalSubtitle?: string;
  defaultValues?: Record<string, string | number>;
};

export type ModalFormContextInterface = [
  {
    form?: FormSystemName;
    options?: OpenModalFormOptions;
  },
  {
    openModalForm?: (
      form: FormSystemName,
      options?: OpenModalFormOptions,
    ) => void;
    setModalFormHeadline?: (headline: string) => void;
    unsetModalFormHeadline?: () => void;
    setModalFormSubtitle?: (subtitle: string) => void;
    unsetModalFormSubtitle?: () => void;
    closeModalForm?: () => void;
    modalIsOpen?: () => boolean;
  },
];

const ModalFormContext = createContext<ModalFormContextInterface>([
  {
    form: undefined,
    options: {},
  },
  {},
]);

export const ModalFormProvider: ParentComponent<{
  form?: FormSystemName;
  options?: OpenModalFormOptions;
}> = (props) => {
  const [state, setState] = createStore({
      // eslint-disable-next-line solid/reactivity
      form: props.form || undefined,
      // eslint-disable-next-line solid/reactivity
      options: props.options || {},
    }),
    store: ModalFormContextInterface = [
      state,
      {
        openModalForm(name: FormSystemName, options?: OpenModalFormOptions) {
          if (DEV) {
            console.groupCollapsed("🧮 Modal form invoked");
            console.log("Form:", name);
            console.log("Options:", options);
            console.groupEnd();
          }

          options = options || {};
          options.defaultValues = options?.defaultValues || {};

          if (options?.wrapper?.program) {
            options.defaultValues!.program_nid =
              options.wrapper.program.drupal_internal__nid;
          }

          if (options?.lot) {
            options.defaultValues!.lot_id = options.lot.drupal_internal__id;
          }

          setState({
            form: name,
            options: options || {},
          });
        },
        setModalFormHeadline(headline: string) {
          setState("options", "modalHeadline", headline);
        },
        unsetModalFormHeadline() {
          setState("options", "modalHeadline", undefined);
        },
        setModalFormSubtitle(subtitle: string) {
          setState("options", "modalSubtitle", subtitle);
        },
        unsetModalFormSubtitle() {
          setState("options", "modalSubtitle", undefined);
        },
        closeModalForm() {
          setState({
            form: undefined,
            options: {},
          });
        },
        modalIsOpen() {
          return !(state.form === null || state.form === undefined);
        },
      },
    ];

  return (
    <ModalFormContext.Provider value={store}>
      {props.children}
    </ModalFormContext.Provider>
  );
};

export function useModalFormContext() {
  const context = useContext(ModalFormContext);
  if (context === undefined) {
    throw new Error(
      "useModalFormContext must be used within a ModalFormContext.Provider",
    );
  }
  return context;
}
